import axiosIns from "@/libs/axios";

const api = "profesionales";

export default {
    listarProfesionales: () => axiosIns.get(`${api}`),
    crearProfesional: (params) => axiosIns.post(`${api}`, params),

    verProfesional: (idProfesional) => axiosIns.get(`${api}/${idProfesional}`),
    editarProfesional: (params) => axiosIns.put(`${api}/${params.id}`, params),
    //entidades
    listarEntidadesProfesional: (idProfesional) =>
        axiosIns.get(`${api}/${idProfesional}/entidades`),
    vincularProfesionalEntidad: (params) =>
        axiosIns.post(`${api}/${params.id_user}/entidades`, params),
    retirarProfesionalEntidad: (params) =>
        axiosIns.put(
            `${api}/${params.id_user_profesional}/entidades/${params.id_user_entidad}`
        ),
    //asistencias
    listarAsistenciasProfesional: (idProfesional, params) =>
        axiosIns.get(`${api}/${idProfesional}/asistencias`, { params }),
    searchPacientes: (idProfesional, params) =>
        axiosIns.get(`${api}/${idProfesional}/asistencias/filtro/pacientes`, {
            params,
        }),
};
