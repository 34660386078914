<template>
    <div>
        <b-row>
            <b-col>
                <b-card>
                    <b-row>
                        <b-col cols="auto">
                            <b-avatar
                                variant="dark"
                                rounded
                                size="100"
                                :src="profesional.foto"
                            ></b-avatar>
                        </b-col>
                        <b-col
                            class="d-flex flex-row flex-wrap align-items-center mr-2"
                        >
                            <div class="col-auto px-2 py-1">
                                <div class="mb-1">
                                    <feather-icon
                                        icon="UserIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    {{ profesional.nombre }}
                                    {{ profesional.apellido }}
                                </div>
                                <div>
                                    <feather-icon
                                        icon="CheckIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    <b-badge
                                        v-if="profesional.activo"
                                        rounded
                                        variant="success"
                                    >
                                        Activo
                                    </b-badge>
                                    <b-badge v-else rounded variant="danger">
                                        Inactivo
                                    </b-badge>
                                </div>
                            </div>
                            <div class="col-auto px-2 py-1">
                                <div class="mb-1">
                                    <feather-icon
                                        icon="HashIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    {{ profesional.identificacion }}
                                </div>
                                <div>
                                    <feather-icon
                                        icon="FlagIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    {{ getEdad(profesional.fecha_nacimiento) }}
                                    años
                                </div>
                            </div>
                            <div class="col-auto px-2 py-1">
                                <div class="mb-1">
                                    <feather-icon
                                        icon="CalendarIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    27 may. 2001
                                </div>
                                <div>
                                    <feather-icon
                                        icon="CompassIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    {{ getGenero(profesional.genero) }}
                                </div>
                            </div>
                            <div class="col-auto px-2 py-1">
                                <div class="mb-1">
                                    <feather-icon
                                        icon="MailIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    {{ profesional.email }}
                                </div>
                                <div>
                                    <feather-icon
                                        icon="PhoneIcon"
                                        size="25"
                                        class="mr-1"
                                    ></feather-icon>
                                    {{ profesional.telefono }}
                                </div>
                            </div>
                        </b-col>
                        <div class="col text-right position-absolute">
                            <b-button
                                variant="flat-success"
                                pill
                                class="p-50"
                                @click="abrirSidebarEditarProfesional"
                            >
                                <feather-icon
                                    icon="EditIcon"
                                    size="20"
                                ></feather-icon>
                            </b-button>
                            <b-button
                                variant="flat-danger"
                                pill
                                class="p-50"
                                @click="abrirmodalEliminarProfesional"
                            >
                                <feather-icon
                                    icon="Trash2Icon"
                                    size="20"
                                ></feather-icon>
                            </b-button>
                        </div>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="6" md="6" xs="12">
                <b-card>
                    <b-card-title class="mb-50">Entidades</b-card-title>
                    <b-button
                        variant="primary"
                        pill
                        size="sm"
                        class="btn-agregar-entidad p-50"
                        @click="abrirModalVincularProfesional"
                    >
                        <feather-icon icon="PlusIcon" size="20"></feather-icon>
                    </b-button>
                    <b-row
                        class="overflow-auto"
                        style="height: calc(100vh - 60vh)"
                    >
                        <b-col>
                            <div
                                v-for="(entidad, index) in entidades"
                                :key="index"
                                class="row py-1"
                            >
                                <div class="col">
                                    <p class="mb-50 font-weight-bolder">
                                        {{ entidad.nombre }}
                                    </p>
                                    <span class="mb-0 pb-0 font-small-2">
                                        Desde
                                        {{
                                            formatDate(entidad.fecha_afiliacion)
                                        }}
                                    </span>
                                    <span
                                        class="mb-0 pb-0 font-small-2"
                                        v-if="!entidad.estado"
                                    >
                                        - Hasta
                                        {{
                                            formatDate(
                                                entidad.fecha_desafiliacion
                                            )
                                        }}
                                    </span>
                                </div>
                                <div class="col text-center">
                                    <p class="mb-25 font-weight-bold">
                                        Asistencias
                                    </p>
                                    <div class="row justify-content-around">
                                        <b-badge
                                            variant="success"
                                            v-b-tooltip.hover.top="'Realizadas'"
                                        >
                                            {{ entidad.realizadas }}</b-badge
                                        >
                                        <b-badge
                                            variant="info"
                                            v-b-tooltip.hover.top="
                                                'Reprogramadas'
                                            "
                                        >
                                            {{ entidad.reprogramadas }}</b-badge
                                        >
                                        <b-badge
                                            variant="danger"
                                            v-b-tooltip.hover.top="'Canceladas'"
                                        >
                                            {{ entidad.canceladas }}</b-badge
                                        >
                                    </div>
                                </div>
                                <div class="col-auto my-auto mr-2">
                                    <b-dropdown
                                        variant="link"
                                        no-caret
                                        class="py-auto"
                                        :disabled="
                                            !entidadesAdmin.includes(
                                                entidad.id_entidad
                                            )
                                        "
                                    >
                                        <template v-slot:button-content>
                                            <feather-icon
                                                icon="MoreVerticalIcon"
                                            ></feather-icon>
                                        </template>

                                        <b-dropdown-item
                                            :disabled="!entidad.estado"
                                            @click="
                                                retirarProfesionalEntidad(
                                                    entidad.id
                                                )
                                            "
                                        >
                                            <feather-icon
                                                icon="Trash2Icon"
                                            ></feather-icon>
                                            Retirar
                                        </b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col xl="6" md="6" xs="12">
                <b-card>
                    <b-card-title class="mb-50">Asistencias</b-card-title>
                    <!-- filtro asistencias -->
                    <div
                        v-if="showFiltro"
                        :class="`filtro-cuadro p-1 rounded bg-white shadow-lg ${
                            showFiltro
                                ? 'animate__animated animate__bounceInRight'
                                : ''
                        }`"
                    >
                        <b-button
                            variant="outline-danger"
                            class="p-50 float-right"
                            pill
                            @click="showFiltro = false"
                        >
                            <feather-icon icon="XIcon"></feather-icon>
                        </b-button>
                        <b-row class="p-50 pb-0 mb-0 mt-1">
                            <b-col v-if="paramsBaseFiltro.length" cols="5"
                                >Campo</b-col
                            >
                            <b-col v-if="paramsBaseFiltro.length">Valor</b-col>

                            <div v-else class="m-auto">
                                No hay campos agregados.
                            </div>
                        </b-row>
                        <b-row
                            v-for="(param, index) in paramsBaseFiltro"
                            :key="index"
                            class="px-50 my-50"
                        >
                            <b-col cols="5" class="pr-0">
                                <v-select
                                    v-model="paramsBaseFiltro[index]"
                                    @input="paramSelected"
                                    :options="paramsFiltro"
                                    :filterable="false"
                                    :clearable="false"
                                    :disabled="
                                        param == null
                                            ? false
                                            : !paramsFiltro[param].selectable
                                    "
                                    :reduce="(option) => option.id"
                                    :selectable="
                                        (option) => option.selectable == true
                                    "
                                ></v-select>
                            </b-col>
                            <b-col class="pr-0">
                                <v-select
                                    v-if="param != null && param == 2"
                                    v-model="paramsFiltro[param].value"
                                    @search="
                                        (search, loading) => {
                                            loading(true);
                                            searchPacientes(search).then(() =>
                                                loading(false)
                                            );
                                        }
                                    "
                                    :options="pacientesFiltro"
                                    :reduce="(option) => option.id_user"
                                >
                                    <template v-slot:no-options>
                                        No se encontraron coincidencias.
                                    </template>
                                    <template
                                        v-slot:selected-option="{ label }"
                                    >
                                        <div
                                            style="width: 110px"
                                            class="tres-puntos"
                                        >
                                            {{ label }}
                                        </div>
                                    </template>
                                </v-select>
                                <b-form-input
                                    v-if="
                                        param != null &&
                                        ![2, 3, 4].includes(param)
                                    "
                                    v-model="paramsFiltro[param].value"
                                ></b-form-input>
                                <b-form-datepicker
                                    v-if="
                                        param != null && [3, 4].includes(param)
                                    "
                                    v-model="paramsFiltro[param].value"
                                    label-reset-button="Borrar"
                                    reset-button
                                    placeholder="Fecha"
                                    style="height: 34px"
                                    :date-format-options="{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                    }"
                                ></b-form-datepicker>
                            </b-col>
                            <b-col cols="auto" class="pl-0 mt-25">
                                <b-button
                                    variant="flat-danger"
                                    pill
                                    class="p-50"
                                    @click="deleteParam(index)"
                                >
                                    <feather-icon icon="XIcon"></feather-icon>
                                </b-button>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col class="text-center py-1">
                                <b-button
                                    variant="outline-info"
                                    pill
                                    class="p-50 mr-50"
                                    @click="addParamFiltro"
                                >
                                    <feather-icon
                                        icon="PlusIcon"
                                    ></feather-icon>
                                </b-button>
                                <b-button
                                    variant="outline-success"
                                    pill
                                    class="py-50 mr-50"
                                    @click="filtrar"
                                    >Filtrar</b-button
                                >
                                <b-button
                                    variant="outline-danger"
                                    pill
                                    class="py-50 px-50"
                                    @click="resetParams"
                                >
                                    <feather-icon
                                        icon="RefreshCcwIcon"
                                    ></feather-icon>
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>
                    <b-button
                        variant="dark"
                        pill
                        class="btn-filtro-asistencias p-50"
                        @click="showFiltro = !showFiltro"
                    >
                        <feather-icon
                            icon="SearchIcon"
                            size="20"
                        ></feather-icon>
                    </b-button>
                    <b-row
                        class="overflow-auto"
                        style="height: calc(100vh - 60vh)"
                        id="infinite-list"
                    >
                        <div
                            v-for="(asistencia, index) in asistencias"
                            :key="index"
                            class="px-1 py-1 mt-1 w-100"
                        >
                            <b-row>
                                <b-col cols="3">
                                    <div>
                                        <p
                                            class="mb-0 font-small-5 font-weight-bolder"
                                        >
                                            Asistencia
                                        </p>
                                        <p class="mb-0 pb-50">
                                            No. {{ asistencia.id }}
                                        </p>
                                    </div>
                                    <div class="text-left">
                                        <p class="font-small-3 mb-0">
                                            {{
                                                formatDate(
                                                    asistencia.fecha,
                                                    "DD MMM YYYY"
                                                )
                                            }}
                                        </p>
                                        <p class="font-small-2 mb-0 pb-75">
                                            {{
                                                formatDate(
                                                    asistencia.fecha,
                                                    "hh:mm a"
                                                )
                                            }}
                                        </p>
                                    </div>
                                    <div>
                                        <b-badge
                                            v-show="asistencia.estado == 0"
                                            variant="warning"
                                        >
                                            Agendada
                                        </b-badge>
                                        <b-badge
                                            v-show="asistencia.estado == 1"
                                            variant="success"
                                        >
                                            Realizada
                                        </b-badge>
                                        <b-badge
                                            v-show="asistencia.estado == 2"
                                            variant="info"
                                        >
                                            Reprogramada
                                        </b-badge>
                                        <b-badge
                                            v-show="asistencia.estado == 3"
                                            variant="danger"
                                        >
                                            Cancelada
                                        </b-badge>
                                    </div>
                                </b-col>
                                <b-col cols="4">
                                    <div>
                                        <p class="pb-0 font-weight-bolder">
                                            Entidad
                                        </p>
                                        <p>
                                            {{
                                                asistencia.entidad ||
                                                "No registra"
                                            }}
                                        </p>
                                    </div>
                                    <div>
                                        <p class="pb-0 font-weight-bolder">
                                            Paciente
                                        </p>
                                        <p class="mb-0">
                                            {{
                                                asistencia.paciente ||
                                                "No registra"
                                            }}
                                        </p>
                                    </div>
                                </b-col>
                                <b-col>
                                    <div>
                                        <p class="font-weight-bolder">Asunto</p>

                                        <div>
                                            <p>
                                                {{
                                                    asistencia.asunto ||
                                                    "No registra"
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="font-weight-bolder">
                                            Creada por
                                        </p>

                                        <div>
                                            <p>
                                                {{
                                                    asistencia.creado_por ||
                                                    "No registra"
                                                }}({{
                                                    getRol(
                                                        asistencia.creado_por_tipo
                                                    )
                                                }})
                                            </p>
                                        </div>
                                    </div>
                                </b-col>
                            </b-row>
                        </div>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <!-- modal eliminar entidad -->
        <modal-general
            title="Eliminar profesional"
            ref="refmodalEliminarProfesional"
            text="¿Desea eliminar este profesional?"
            aceptar="Eliminar"
        >
        </modal-general>

        <!-- modal vincular profesional a entidad -->
        <modal-general
            ref="refmodalVincularProfesionalEntidad"
            aceptar="Vincular"
            title="Vincular profesional"
            @aceptar="vincularProfesionalEntidad"
        >
            <ValidationObserver ref="modalVincular">
                <div class="mx-auto my-auto" style="height: 180px">
                    <p class="text-center">
                        Escoja a cuál entidad desea vincular este profesional.
                    </p>
                    <ValidationProvider
                        v-slot="{ errors }"
                        name="entidad"
                        rules="required"
                    >
                        <v-select
                            v-model="entidadSelected"
                            :options="entidadesDisponibles"
                            label="nombre"
                            :reduce="(entidad) => entidad.id"
                        >
                            <template v-slot:no-options>
                                No se encontraron entidades que coincidan.
                            </template>
                        </v-select>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                </div>
            </ValidationObserver>
        </modal-general>

        <!-- sidebar editar profesional -->
        <sidebar-editar-profesional
            @update="fetchVerProfesional"
            ref="refsidebarEditarProfesional"
        ></sidebar-editar-profesional>
    </div>
</template>
<script>
import { useRouter } from "@/@core/utils/utils.js";
import Profesionales from "@/services/profesionales";
import Users from "@/services/users";
import { ref, onMounted, reactive } from "@vue/composition-api";
export default {
    emits: ["update"],
    components: {
        SidebarEditarProfesional: () =>
            import("./sidebars/SidebarEditarProfesional.vue"),
    },
    setup(props, context) {
        const route = useRouter();

        const showFiltro = ref(false);

        const profesional = ref({});

        const entidadesAdmin = ref([]);

        const entidadesDisponibles = ref([]);

        const entidadSelected = ref(null);

        const entidades = ref([]);

        const asistencias = ref([]);

        const paramsBaseFiltro = ref([]);

        const paramsRequest = ref({});

        const paramsFiltro = ref([
            {
                id: 0,
                label: "Id",
                param: "id",
                value: undefined,
                selectable: true,
            },
            {
                id: 1,
                label: "Asunto",
                param: "asunto",
                value: undefined,
                selectable: true,
            },
            {
                id: 2,
                label: "Paciente",
                param: "id_user_paciente",
                value: undefined,
                selectable: true,
            },
            {
                id: 3,
                label: "Fecha inicio",
                param: "fecha_inicio",
                value: undefined,
                selectable: true,
            },
            {
                id: 4,
                label: "Fecha fin",
                param: "fecha_fin",
                value: undefined,
                selectable: true,
            },
        ]);

        const pacientesFiltro = ref([]);

        function abrirSidebarEditarProfesional() {
            context.refs.refsidebarEditarProfesional.toggle(profesional.value);
        }

        function abrirModalVincularProfesional() {
            entidadSelected.value = null;
            fetchOptions();

            context.refs.refmodalVincularProfesionalEntidad.toggle();
        }

        function abrirmodalEliminarProfesional() {
            context.refs.refmodalEliminarProfesional.toggle();
        }

        async function fetchVerProfesional() {
            try {
                const { data } = await Profesionales.verProfesional(
                    route.route.value.params.id
                );

                profesional.value = data.data;
            } catch (error) {
                context.root.catch(error);
            }
        }

        async function fetchListarEntidades() {
            try {
                const { data } = await Profesionales.listarEntidadesProfesional(
                    route.route.value.params.id
                );
                entidades.value = data.data;
            } catch (error) {
                context.root.catch(error);
            }
        }

        async function fetchOptions() {
            try {
                const { data } = await Users.getEntidadesAdmin();

                entidadesAdmin.value = data.data.map((el) => el.id);
                entidadesDisponibles.value = data.data;
            } catch (error) {
                context.root.catch(error);
            }
        }

        async function vincularProfesionalEntidad() {
            try {
                let validate = await context.refs.modalVincular.validate();

                if (!validate)
                    return context.root.notificacion(
                        "Por favor selecciona una entidad",
                        null,
                        "warning"
                    );

                const params = {
                    id_user: profesional.value.id,
                    id_entidad: entidadSelected.value,
                };
                const { data } = await Profesionales.vincularProfesionalEntidad(
                    params
                );

                context.root.notificacion(data.message, null, "success");
                fetchListarEntidades();
                fetchVerProfesional();
                context.refs.refmodalVincularProfesionalEntidad.toggle();
            } catch (error) {
                context.root.catch(error);
            }
        }

        async function retirarProfesionalEntidad(id_user_entidad) {
            try {
                const { data } = await Profesionales.retirarProfesionalEntidad({
                    id_user_entidad,
                    id_user_profesional: profesional.value.id,
                });

                context.root.notificacion(data.message, null, "success");

                fetchListarEntidades();
                fetchVerProfesional();
            } catch (error) {
                context.root.catch(error);
            }
        }

        async function fetchListarAsistencias(
            offset = asistencias.value.length,
            filtro = false
        ) {
            try {
                const { data } =
                    await Profesionales.listarAsistenciasProfesional(
                        profesional.value.id,
                        { ...{ offset }, ...paramsRequest.value }
                    );

                filtro
                    ? (asistencias.value = data.data)
                    : (asistencias.value = [
                          ...asistencias.value,
                          ...data.data,
                      ]);
            } catch (error) {
                context.root.catch(error);
            }
        }

        function paramSelected(idParamFiltro) {
            paramsFiltro.value[idParamFiltro].selectable = false;
        }

        function addParamFiltro(param) {
            if (paramsBaseFiltro.value.length < 5) {
                paramsBaseFiltro.value.push(null);
            } else {
                context.root.notificacion(
                    "Solo puedes agregar 4 opciones",
                    null,
                    "warning"
                );
            }
        }

        function resetParams() {
            paramsBaseFiltro.value = [];
            paramsRequest.value = {};
            paramsFiltro.value.map((el) => {
                el.selectable = true;
                el.value = undefined;
                return el;
            });
        }

        function deleteParam(index) {
            const param = paramsBaseFiltro.value.splice(index, 1);
            delete paramsRequest.value[paramsFiltro.value[param[0]].param];
            paramsFiltro.value[param[0]].selectable = true;
            paramsFiltro.value[param[0]].value = undefined;
        }

        function filtrar() {
            paramsFiltro.value.map((el) => {
                if (!el.value) el.value = undefined;
                else paramsRequest.value[el.param] = el.value;
                return el;
            });

            showFiltro.value = false;

            fetchListarAsistencias(0, true);
        }

        async function searchPacientes(search) {
            try {
                const { data } = await Profesionales.searchPacientes(
                    profesional.value.id,
                    { search }
                );

                pacientesFiltro.value = data.data;
            } catch (error) {
                context.root.catch(error);
            }
        }

        onMounted(async () => {
            await fetchVerProfesional();
            await fetchListarEntidades();
            await fetchListarAsistencias();

            const listElm = document.querySelector("#infinite-list");
            listElm.addEventListener("scroll", (e) => {
                if (
                    listElm.scrollTop + listElm.clientHeight >=
                    listElm.scrollHeight
                ) {
                    fetchListarAsistencias();
                }
            });
        });

        return {
            entidades,
            asistencias,
            showFiltro,
            entidadesAdmin,
            entidadesDisponibles,
            entidadSelected,
            abrirSidebarEditarProfesional,
            abrirModalVincularProfesional,
            abrirmodalEliminarProfesional,
            fetchVerProfesional,
            fetchListarEntidades,
            vincularProfesionalEntidad,
            retirarProfesionalEntidad,
            profesional,
            paramsFiltro,
            paramsBaseFiltro,
            paramSelected,
            pacientesFiltro,
            addParamFiltro,
            searchPacientes,
            resetParams,
            deleteParam,
            filtrar,
        };
    },
};
</script>
<style lang="css" scope>
.list-group {
    overflow: auto;
    height: 50vh;
    border: 2px solid #dce4ec;
    border-radius: 5px;
}
.btn-agregar-entidad {
    position: absolute;
    right: 4%;
    z-index: 20;
}
.btn-filtro-asistencias {
    position: absolute;
    right: 4%;
    z-index: 10;
}
.filtro-cuadro {
    display: block;
    position: absolute;
    min-height: 120px;
    width: 450px;
    right: 14%;
    z-index: 10;
}
</style>
